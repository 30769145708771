import { useCompaniesStore } from "@/components/Companies/CompaniesStore"
import { useCompanyDriversStore } from "@/components/Companies/companyDriversStore"
import { useEmployeesStore } from "@/components/Employees/EmployeesStore"
import { useCostConfigurationsStore } from "@/components/Finance/CostConfigurationsStore"
import { useVatsStore } from "@/components/Finance/Taxes/VatsStore"
import { useJobsStore } from "@/components/Jobs/JobsStore"
import { useCompanyVehiclesStore } from "@/components/Vehicles/CompanyVehiclesStore"
import { useVehiclesStore } from "@/components/Vehicles/VehiclesStore"

export const storesInitializer = (
  tenantCompany,
  webSocket,
  JobsServiceClient = null,
  AssetsServiceClient = null,
  FinancesServiceClient = null,
  LocationsServiceClient = null
) => {
  if (!tenantCompany) {
    console.error("Tenant company is required but not provided.")
    return
  } else {
    console.log("Tenant company is required and provided.")
  }
  if (!FinancesServiceClient) {
    console.error("FinancesServiceClient is required but not provided.")
    return
  }
  useCompaniesStore().initialize(tenantCompany, webSocket)
  useCompanyDriversStore().initialize(tenantCompany, webSocket)
  useCompanyVehiclesStore().initialize(tenantCompany, webSocket)
  useVehiclesStore().initialize(tenantCompany)
  useEmployeesStore().initialize(tenantCompany, webSocket)
  useVatsStore().initialize(tenantCompany)
  useCostConfigurationsStore().initialize(tenantCompany, FinancesServiceClient, webSocket)
  useJobsStore().initialize(tenantCompany, webSocket)
}
