export function saveToLocalStorage(key, data) {
  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (error) {
    console.error("Error saving to localStorage:", error)
  }
}

export function loadFromLocalStorage(key) {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    console.error("Error loading from localStorage:", error)
    return null
  }
}
