import { JobToBuyOrder, JobToSellOrder } from "@/components/Jobs/JobOrders"
let fallbackTenant = null

// eslint-disable-next-line camelcase
export const HACK_JobAddBuySellOrders = (job, tenant = null) => {
  if (tenant.id) fallbackTenant = tenant
  job.orderBuy = JobToBuyOrder(job, tenant ?? fallbackTenant)
  job.orderSell = JobToSellOrder(job, tenant)
}

// eslint-disable-next-line camelcase
export const HACK_JobArrayAddBuySellOrders = (jobs, tenant = null) => {
  if (tenant.id) fallbackTenant = tenant
  jobs.forEach((j) => {
    HACK_JobAddBuySellOrders(j, tenant ?? fallbackTenant)
  })
}
