import { getAuth } from "firebase/auth"
import pako from "pako"
import { defineStore } from "pinia"
import { AuthenticatedHttp, FirebaseTokenLoader, Job, JobsService, JobStatus, SDKObject } from "telarya-sdk"
import { toRaw } from "vue"

// eslint-disable-next-line camelcase
import { HACK_JobAddBuySellOrders, HACK_JobArrayAddBuySellOrders } from "@/components/Jobs/HACK_JobAddBuySellOrders"
import generateEndpoint from "@/endpoint"
const storeObject = "Jobs"
const storeName = storeObject + "Store"
export const useJobsStore = defineStore("jobs", {
  state: () => ({
    jobsByTenant: {}, // Structure: { [tenantId]: { [rangeKey]: jobs } }
    isFetching: {}, // Structure: { [tenantId]: { [rangeKey]: true/false } }
    sdkService: null,
    isInitialized: false,
    tenantId: null,
    overdueJobsCount: null, // Holds overdue jobs count
    onHoldJobsCount: null, // Holds on-hold jobs count
    overdueJobsLastFetched: null, // Timestamp of the last overdue jobs fetch
    onHoldJobsLastFetched: null, // Timestamp of the last on-hold jobs fetch
    lastFetchedRanges: {} // Tracks the last fetched range for each tenant
  }),

  actions: {
    initializeSdkService() {
      if (this.sdkService) {
        return
      }
      this.sdkService = new JobsService(
        generateEndpoint("jobs"),
        new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
      )
      this.subscribeToPusher()
      this.isInitialized = true
    },
    initialize(tenant, webSocket) {
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided.`)
      }
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided during initialization.`)
      }
      if (!webSocket) {
        throw new Error(`${storeName}: Invalid webSocket service provided during initialization.`)
      }
      this.tenantId = tenant.id

      this.pusher = toRaw(webSocket)
    },
    async getJobs(tenantId, startDate, endDate) {
      this.initializeSdkService()

      if (!this.jobsByTenant[tenantId]) {
        this.jobsByTenant[tenantId] = {}
        this.isFetching[tenantId] = {}
      }

      const rangeKey = `${startDate.toISOString()}_${endDate.toISOString()}`

      if (this.jobsByTenant[tenantId][rangeKey]) {
        // Store the last fetched range for the tenant
        this.lastFetchedRanges[tenantId] = { startDate, endDate }
        return this.jobsByTenant[tenantId][rangeKey]
      }

      if (this.isFetching[tenantId][rangeKey]) {
        return []
      }

      this.isFetching[tenantId][rangeKey] = true

      try {
        const jobs = await this.sdkService.getJobs(undefined, startDate, endDate)
        HACK_JobArrayAddBuySellOrders(jobs, { id: tenantId })
        this.jobsByTenant = {
          ...this.jobsByTenant,
          [tenantId]: {
            ...this.jobsByTenant[tenantId],
            [rangeKey]: jobs
          }
        }

        // Store the last fetched range for the tenant
        this.lastFetchedRanges[tenantId] = { startDate, endDate }

        return jobs
      } catch (error) {
        console.error(`Failed to fetch jobs for tenant ${tenantId}, range ${rangeKey}:`, error)
        return []
      } finally {
        this.isFetching[tenantId][rangeKey] = false
      }
    },
    subscribeToPusher() {
      if (!this.tenantId) {
        throw new Error(`${storeName}: Tenant ID is required for Pusher subscription.`)
      }

      if (!this.pusher) {
        throw new Error(`${storeName}: No webSocket service.`)
      }

      const channelName = `${this.tenantId}_${storeObject}`
      const channel = toRaw(this.pusher).subscribe(channelName)

      channel.bind("Create", async (data) => {
        this.logToConsole(`Pusher message received on channel ${channelName} (Create):`, "info")

        let instance

        if (data.compressedData) {
          try {
            // Decompress the data
            const compressedBytes = Uint8Array.from(atob(data.compressedData), (c) => c.charCodeAt(0))
            const decompressedBytes = pako.inflate(compressedBytes)
            const decompressedJson = new TextDecoder("utf-8").decode(decompressedBytes)
            const parsedJson = lowercaseKeys(JSON.parse(decompressedJson))
            instance = new Job()
            instance.init(parsedJson)
            instance = SDKObject.ProxyCreator(instance)
            console.log("Decompressed job instance:", instance)
          } catch (error) {
            this.logToConsole(`Failed to decompress data: ${error.message}`, "error")
            return
          }
        } else {
          // Fetch the full job instance using the job ID from the message
          instance = await this.sdkService.getJob(data.id)
        }

        HACK_JobAddBuySellOrders(instance, { id: this.tenantId })
        // Check if the job has datesAll
        if (Array.isArray(instance.datesAll) && instance.datesAll.length > 0) {
          // Convert datesAll to Date objects
          const jobDates = instance.datesAll.map((date) => new Date(date))

          for (const [tenantId, ranges] of Object.entries(this.jobsByTenant)) {
            for (const [rangeKey, jobs] of Object.entries(ranges)) {
              const [start, end] = rangeKey.split("_").map((date) => new Date(date))

              // Check if any date in datesAll falls within the current range
              const isDateInRange = jobDates.some((jobDate) => jobDate >= start && jobDate <= end)

              if (isDateInRange) {
                // Add the job to the correct rangeKey if it doesn't already exist
                if (!this.jobsByTenant[tenantId][rangeKey].some((job) => job.id === instance.id)) {
                  this.jobsByTenant[tenantId][rangeKey] = [...this.jobsByTenant[tenantId][rangeKey], instance]
                  this.logToConsole(`Added new job to tenant ${tenantId}, range ${rangeKey}.`, "info")
                }
              }
            }
          }
        }

        console.log(instance)
      })

      channel.bind("Delete", async (data) => {
        this.logToConsole(`Pusher message received on channel ${channelName} (Delete):`, "info")

        // Check if the job has datesAll
        if (Array.isArray(data.datesAll) && data.datesAll.length > 0) {
          // Convert datesAll to Date objects
          const jobDates = data.datesAll.map((date) => new Date(date))

          for (const [tenantId, ranges] of Object.entries(this.jobsByTenant)) {
            for (const [rangeKey, jobs] of Object.entries(ranges)) {
              const [start, end] = rangeKey.split("_").map((date) => new Date(date))

              // Check if any date in datesAll falls within the current range
              const isDateInRange = jobDates.some((jobDate) => jobDate >= start && jobDate <= end)

              if (isDateInRange) {
                // Find the index of the job to delete
                const jobIndex = this.jobsByTenant[tenantId][rangeKey].findIndex((job) => job.id === data.id)

                if (jobIndex !== -1) {
                  // Remove the job from the array
                  this.jobsByTenant[tenantId][rangeKey].splice(jobIndex, 1)
                  this.logToConsole(`Deleted job from tenant ${tenantId}, range ${rangeKey}.`, "info")
                }
              }
            }
          }
        }
      })

      this.logToConsole(`Subscribed to Pusher channel: ${channelName}`, "info")
    },
    async refreshLastFetchedRange() {
      if (!this.tenantId || !this.lastFetchedRanges[this.tenantId]) {
        console.warn("No last fetched range available to refresh.")
        return
      }

      const { startDate, endDate } = this.lastFetchedRanges[this.tenantId]
      this.logToConsole(
        `Refreshing last fetched range for tenant ${
          this.tenantId
        }: ${startDate.toISOString()} - ${endDate.toISOString()}`
      )

      try {
        const refreshedJobs = await this.getJobs(this.tenantId, startDate, endDate)
        const rangeKey = `${startDate.toISOString()}_${endDate.toISOString()}`
        this.jobsByTenant[this.tenantId][rangeKey] = refreshedJobs
        this.logToConsole(`Successfully refreshed jobs for tenant ${this.tenantId}.`, "info")
      } catch (error) {
        console.error("Failed to refresh last fetched range:", error)
      }
    },
    logToConsole(m, type = "info") {
      console.log(storeName + " " + `[${type.toUpperCase()}] ${m}`)
    },
    async fetchOverdueJobsCount() {
      const now = new Date()
      if (
        this.overdueJobsLastFetched &&
        now - this.overdueJobsLastFetched < 12 * 60 * 60 * 1000 // 12 hours
      ) {
        return this.overdueJobsCount
      }

      try {
        this.overdueJobsCount = await this.sdkService.getOverdueJobsCount()
        this.overdueJobsLastFetched = now
      } catch (error) {
        console.error("Failed to fetch overdue jobs count:", error)
      }
      return this.overdueJobsCount
    },
    async fetchOnHoldJobsCount() {
      const now = new Date()
      if (
        this.onHoldJobsLastFetched &&
        now - this.onHoldJobsLastFetched < 3 * 60 * 60 * 1000 // 3 hours
      ) {
        return this.onHoldJobsCount
      }

      try {
        this.onHoldJobsCount = await this.sdkService.getJobsCount([JobStatus.OnHold])
        this.onHoldJobsLastFetched = now
      } catch (error) {
        console.error("Failed to fetch on-hold jobs count:", error)
      }
      return this.onHoldJobsCount
    }
  }
})

function lowercaseKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(lowercaseKeys)
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.charAt(0).toLowerCase() + key.slice(1), lowercaseKeys(value)])
    )
  } else {
    return obj
  }
}
