import { message } from "ant-design-vue"
import { getAuth } from "firebase/auth"
import { defineStore } from "pinia"
import { AuthenticatedHttp, FinancesService, FirebaseTokenLoader } from "telarya-sdk"

import { loadFromLocalStorage, saveToLocalStorage } from "@/components/Other/LocalStorageHelper"
import generateEndpoint from "@/endpoint"
const storeObject = "Vats"
const storeName = storeObject + "Store"

export const useVatsStore = defineStore(storeName, {
  state: () => ({
    records: [],
    isFetching: false,
    fetchPromise: null,
    isInitialized: false,
    lastFetched: null, // For caching validity
    sdkService: null, // Holds the SDK service instance
    tenantId: null, // Dynamically injected tenant ID
    tenantCountry: null // Dynamically injected tenant ID
  }),
  getters: {
    get(state) {
      if (!this.isInitialized) {
        throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' with a tenant first.`)
      }
      return state.records
    }
  },
  actions: {
    setTenant(tenant) {
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided.`)
      }
      this.tenantId = tenant.id
      this.tenantCountry = tenant?.publicAddress?.country
      this.logToConsole(`Tenant set to: ${tenant.id}`, "info")
    },
    initializeSdkService() {
      if (this.sdkService) {
        return
      }
      this.sdkService = new FinancesService(
        generateEndpoint("finances"),
        new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
      )
    },
    getById(id) {
      if (!this.isInitialized) {
        throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' with a tenant first.`)
      }
      return this.records.find((d) => d.id === id)
    },
    async fetch() {
      if (!this.tenantId) {
        this.logToConsole(`Tenant ID not set. Cannot fetch ${storeObject}.`, "error")
        return
      }

      if (this.isFetching) {
        return this.fetchPromise
      }

      this.isFetching = true
      this.fetchPromise = (async () => {
        try {
          this.logToConsole(`Fetching ${storeObject} for tenant ${this.tenantId} from server...`)
          this.initializeSdkService()
          console.log("Fetching vats for country", this.tenantCountry)
          const records = await this.sdkService.getVatsForCountry(this.tenantCountry)
          this.records = records
          this.lastFetched = Date.now()

          saveToLocalStorage(`${storeObject}-${this.tenantId}`, {
            records,
            timestamp: this.lastFetched
          })

          this.logToConsole("Updated cache and localStorage", "info")
        } catch (error) {
          this.notifyUser("Error fetching data. " + error.message, "error")
          console.error("Failed to fetch:", error)
        } finally {
          this.isFetching = false
          this.fetchPromise = null
        }
      })()

      return this.fetchPromise
    },
    initialize(tenant) {
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided during initialization.`)
      }
      if (this.isInitialized) {
        return
      }

      this.setTenant(tenant)
      this.initializeSdkService()

      const cachedData = loadFromLocalStorage(`${storeObject}-${this.tenantId}`)
      if (cachedData) {
        const { records, timestamp } = cachedData
        const isCacheValid = Date.now() - timestamp < 27 * 60 * 60 * 1000 // 27 hours
        if (isCacheValid) {
          this.records = records
          this.lastFetched = timestamp
          this.logToConsole(`Loaded valid ${storeObject} for tenant ${this.tenantId} from localStorage`, "info")
        } else {
          this.logToConsole(`Cached ${storeObject} data for tenant ${this.tenantId} is stale`, "info")
        }
      }

      this.fetch()
        .then(() => this.logToConsole(`Fetched ${storeObject} for tenant ${this.tenantId} and updated cache`, "info"))
        .catch((error) => console.error("Error fetching:", error))

      this.isInitialized = true
    },
    notifyUser(m, type = "info") {
      message.error(storeName + " " + `[${type.toUpperCase()}] ${m}`)
    },
    logToConsole(m, type = "info") {
      console.log(storeName + " " + `[${type.toUpperCase()}] ${m}`)
    }
  }
})
