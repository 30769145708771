export function loadTheme(themeName) {
  const head = document.getElementsByTagName("head")[0]
  const link = document.createElement("link")
  link.id = "theme-css"
  link.rel = "stylesheet"
  link.type = "text/css"
  link.href = `${process.env.BASE_URL}css/${themeName}.css`
  link.media = "all"
  head.appendChild(link)
  localStorage.setItem("theme", themeName)
}
