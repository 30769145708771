import { message } from "ant-design-vue"
import { getAuth } from "firebase/auth"
import { defineStore } from "pinia"
import { AssetsService, AuthenticatedHttp, FirebaseTokenLoader } from "telarya-sdk"

import { loadFromLocalStorage, saveToLocalStorage } from "@/components/Other/LocalStorageHelper"
import generateEndpoint from "@/endpoint"
const storeObject = "Vehicles"
const storeName = storeObject + "Store"

export const useVehiclesStore = defineStore(storeName, {
  state: () => ({
    records: [],
    isFetching: false,
    fetchPromise: null,
    isInitialized: false,
    lastFetched: null,
    sdkService: null,
    tenantId: null
  }),
  actions: {
    setTenant(tenant) {
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided.`)
      }
      this.tenantId = tenant.id
      this.logToConsole(`Tenant set to: ${tenant.id}`, "info")
    },
    initializeSdkService() {
      if (this.sdkService) {
        return
      }
      this.sdkService = new AssetsService(
        generateEndpoint("assets"),
        new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
      )
    },
    getById(id) {
      if (!this.isInitialized) {
        throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' with a tenant first.`)
      }
      return this.records.find((d) => d.id === id) || null
    },
    async fetch(id) {
      if (!this.tenantId) {
        this.logToConsole(`Tenant ID not set. Cannot fetch ${storeObject}.`, "error")
        return null
      }

      if (this.isFetching) {
        return this.fetchPromise
      }

      this.isFetching = true
      this.fetchPromise = (async () => {
        try {
          this.logToConsole(`Fetching ${storeObject} with ID ${id} for tenant ${this.tenantId} from server...`)
          this.initializeSdkService()
          const vehicle = await this.sdkService.getVehicle(id)

          if (vehicle) {
            // Update the store
            const existingIndex = this.records.findIndex((v) => v.id === id)
            if (existingIndex !== -1) {
              this.records[existingIndex] = vehicle
            } else {
              this.records.push(vehicle)
            }

            // Save to localStorage
            saveToLocalStorage(`${storeObject}-${this.tenantId}`, {
              records: this.records,
              timestamp: Date.now()
            })

            this.logToConsole("Updated cache and localStorage", "info")
          }

          return vehicle
        } catch (error) {
          this.notifyUser("Error fetching data. " + error.message, "error")
          console.error("Failed to fetch:", error)
          return null
        } finally {
          this.isFetching = false
          this.fetchPromise = null
        }
      })()

      return this.fetchPromise
    },
    async getOrFetchById(id) {
      if (!this.isInitialized) {
        throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' with a tenant first.`)
      }

      // Check if the vehicle exists in the store
      let vehicle = this.getById(id)
      if (vehicle) {
        return vehicle
      }

      vehicle = await this.fetch(id)
      return vehicle
    },
    initialize(tenant) {
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided during initialization.`)
      }
      if (this.isInitialized) {
        return
      }

      this.setTenant(tenant)
      this.initializeSdkService()

      const cachedData = loadFromLocalStorage(`${storeObject}-${this.tenantId}`)
      if (cachedData) {
        const { records, timestamp } = cachedData
        const isCacheValid = Date.now() - timestamp < 27 * 60 * 60 * 1000 // 27 hours
        if (isCacheValid) {
          this.records = records
          this.lastFetched = timestamp
          this.logToConsole(`Loaded valid ${storeObject} for tenant ${this.tenantId} from localStorage`, "info")
        } else {
          this.logToConsole(`Cached ${storeObject} data for tenant ${this.tenantId} is stale`, "info")
        }
      }

      this.isInitialized = true
    },
    notifyUser(m, type = "info") {
      message.error(storeName + " " + `[${type.toUpperCase()}] ${m}`)
    },
    logToConsole(m, type = "info") {
      console.log(storeName + " " + `[${type.toUpperCase()}] ${m}`)
    }
  }
})
