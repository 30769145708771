import { message } from "ant-design-vue"
import { defineStore } from "pinia"
import Pusher from "pusher-js"

import { loadFromLocalStorage, saveToLocalStorage } from "@/components/Other/LocalStorageHelper"

export function createParameteredStoreFactory(
  storeObject,
  fetchFunction,
  objectClass,
  cacheDuration = 2 * 60 * 60 * 1000
) {
  const storeName = storeObject + "Store"

  return defineStore(storeName, {
    state: () => ({
      records: new Map(), // Store records per parameter combination
      isFetching: new Map(), // Track fetching state per parameter combination
      fetchPromises: new Map(), // Track fetch promises per parameter combination
      isInitialized: false,
      lastFetched: new Map(), // Track last fetched time per parameter combination
      sdkService: null, // Use the injected service if provided
      tenantId: null,
      pusher: null
    }),
    getters: {
      get(state) {
        return async (param1, param2) => {
          if (!this.isInitialized) {
            throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' with a tenant first.`)
          }

          const key = JSON.stringify([param1, param2])
          const cachedData = state.records.get(key)

          if (cachedData) {
            // Return cached data if valid
            const lastFetched = state.lastFetched.get(key)
            if (Date.now() - lastFetched < cacheDuration) {
              return cachedData
            } else {
              this.logToConsole(`Cached data for ${key} is stale. Fetching new data...`, "info")
            }
          }

          // If no valid cache, fetch data
          await this.fetch(param1, param2)
          return state.records.get(key)
        }
      }
    },
    actions: {
      setTenant(tenant) {
        if (!tenant || !tenant.id) {
          throw new Error(`${storeName}: Invalid tenant provided.`)
        }
        this.tenantId = tenant.id
      },
      initializeSdkService(sdkService) {
        if (!sdkService) {
          this.logToConsole(`No Service for ${storeName}`, "error")
          throw new Error(`${storeName}: No service provided.`)
        }
        this.sdkService = sdkService
      },
      subscribeToPusher() {
        if (!this.tenantId) {
          throw new Error(`${storeName}: Tenant ID is required for Pusher subscription.`)
        }

        if (!this.pusher) {
          throw new Error(`${storeName}: No webSocket service.`)
        }

        const channelName = `${this.tenantId}_${storeObject}`
        const channel = this.pusher.subscribe(channelName)

        channel.bind("Create", (data) => {
          this.logToConsole(`Pusher message received on channel ${channelName}:`, "info")

          // Create an instance of the provided class dynamically
          const instance = Object.create(objectClass.prototype)
          instance.init(lowercaseKeys(data)) // Use the `init` method to populate data

          // Add the new instance to the records array
          this.records.push(instance)

          this.logToConsole(`Added new ${storeObject} to records:`, "info")
          console.log(instance)
        })

        this.logToConsole(`Subscribed to Pusher channel: ${channelName}`, "info")
      },
      getById(id) {
        if (!this.isInitialized) {
          throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' with a tenant first.`)
        }
        return this.records.find((d) => d.id === id)
      },
      async fetch(param1, param2) {
        if (!this.tenantId) {
          this.logToConsole(`Tenant ID not set. Cannot fetch ${storeObject}.`, "error")
          return
        }

        const key = JSON.stringify([param1, param2])

        if (this.isFetching.get(key)) {
          return this.fetchPromises.get(key) // Return the ongoing fetch promise
        }

        this.isFetching.set(key, true)
        const fetchPromise = (async () => {
          try {
            this.logToConsole(`Fetching ${storeObject} for tenant ${this.tenantId} with params ${key}...`)
            const records = await fetchFunction(this.sdkService, this.tenantId, param1, param2)
            this.records.set(key, records)
            this.lastFetched.set(key, Date.now())

            saveToLocalStorage(`${storeObject}-${this.tenantId}-${key}`, {
              records,
              timestamp: this.lastFetched.get(key)
            })

            this.logToConsole(`Updated cache and localStorage for params ${key}`, "info")
          } catch (error) {
            this.notifyUser(`Error fetching data for params ${key}. ${error.message}`, "error")
            console.error("Failed to fetch:", error)
          } finally {
            this.isFetching.set(key, false)
            this.fetchPromises.delete(key)
          }
        })()

        this.fetchPromises.set(key, fetchPromise)
        return fetchPromise
      },
      initialize(tenant, sdkService, webSocket) {
        if (!tenant || !tenant.id) {
          throw new Error(`${storeName}: Invalid tenant provided during initialization.`)
        }
        if (!sdkService) {
          console.error(`${storeName}: Service is missing during initialization..`)
          throw new Error(`${storeName}: No service provided during initialization..`)
        }
        if (!webSocket) {
          throw new Error(`${storeName}: Invalid webSocket service provided during initialization.`)
        }
        if (this.isInitialized) {
          return
        }

        this.setTenant(tenant)
        this.pusher = webSocket
        this.initializeSdkService(sdkService)

        const cachedDataKeys = Object.keys(localStorage).filter((key) =>
          key.startsWith(`${storeObject}-${this.tenantId}-`)
        )

        cachedDataKeys.forEach((cachedKey) => {
          const cachedData = loadFromLocalStorage(cachedKey)
          if (cachedData) {
            const { records, timestamp } = cachedData
            const isCacheValid = Date.now() - timestamp < cacheDuration
            if (isCacheValid) {
              this.records.set(cachedKey.split("-").slice(2).join("-"), records)
              this.lastFetched.set(cachedKey.split("-").slice(2).join("-"), timestamp)
              this.logToConsole(`Loaded valid ${storeObject} data from localStorage for key: ${cachedKey}`, "info")
            } else {
              this.logToConsole(`Cached ${storeObject} data for key: ${cachedKey} is stale`, "info")
            }
          }
        })

        this.isInitialized = true
      },
      notifyUser(m, type = "info") {
        message.error(storeName + " " + `[${type.toUpperCase()}] ${m}`)
      },
      logToConsole(m, type = "info") {
        console.log(storeName + " " + `[${type.toUpperCase()}] ${m}`)
      }
    }
  })
}

function lowercaseKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(lowercaseKeys)
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.charAt(0).toLowerCase() + key.slice(1), lowercaseKeys(value)])
    )
  } else {
    return obj
  }
}
