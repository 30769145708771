import { User } from "telarya-sdk"

import { createSimpleStoreFactory } from "@/components/Other/createSimpleStoreFactory"

export const useEmployeesStore = createSimpleStoreFactory(
  "Employees",
  (sdkService) => sdkService.getUsersForTenant(), // Fetch function
  User,
  2 * 60 * 60 * 1000 // Cache duration: 2 hours
)
