import { CostConfiguration } from "telarya-sdk/dist/src/Telarya.Services.Finance"

import { createParameteredStoreFactory } from "@/components/Other/createParameteredStoreFactory"

export const useCostConfigurationsStore = createParameteredStoreFactory(
  "CostConfigurations",
  async (sdkService, tenantId, externalReferenceType, externalReferenceId) => {
    return await sdkService.getCostConfigurationsFor(externalReferenceType, externalReferenceId)
  },
  CostConfiguration,
  29 * 60 * 60 * 1000 // Cache duration: 29 hours
)
