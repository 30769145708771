import { Company } from "telarya-sdk"

import { createSimpleStoreFactory } from "@/components/Other/createSimpleStoreFactory"

export const useCompaniesStore = createSimpleStoreFactory(
  "Companies",
  (sdkService) => sdkService.getCompaniesForTenant(), // Fetch function
  Company,
  2 * 60 * 60 * 1000 // Cache duration: 2 hours
)
