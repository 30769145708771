import { Vehicle } from "telarya-sdk"

import { createSimpleStoreFactory } from "@/components/Other/createSimpleStoreFactory"

export const useCompanyVehiclesStore = createSimpleStoreFactory(
  "CompanyVehicles",
  (sdkService) => sdkService.getVehicles(), // Fetch function
  Vehicle,
  29 * 60 * 60 * 1000 // Cache duration: 29 hours
)
