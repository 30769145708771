import { User } from "telarya-sdk"

import { createSimpleStoreFactory } from "@/components/Other/createSimpleStoreFactory"

export const useCompanyDriversStore = createSimpleStoreFactory(
  "CompanyDrivers",
  (sdkService) => sdkService.getDriversForTenant(), // Fetch function
  User,
  21 * 60 * 60 * 1000 // Cache duration: 21 hours
)
